import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ChartModule as VChartModule } from 'angular-highcharts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FamilyMeetingListComponent } from '../learner-management/family-meeting/family-meeting-list/family-meeting-list.component';
import { AppMatModule } from './app-mat.module';
import { AddGoalProgressFormComponent } from './components/add-goal-progress-form/add-goal-progress-form.component';
import { AnnualReviewQuestionnaireComponent } from './components/annual-review-questionnaire/annual-review-questionnaire.component';
import { AreYouSureMemoModalComponent } from './components/are-you-sure-memo-modal/are-you-sure-memo-modal.component';
import { AreYouSureComponent } from './components/are-you-sure-modal/are-you-sure.component';
import { BankableFieldsComponent } from './components/bankable-fields/bankable-fields.component';
import { BaseComponent } from './components/base-component/base-component';
import { CaseExitBannerComponent } from './components/case-exit-banner/case-exit-banner.component';
import { ChartViewerComponent } from './components/charts/chart-viewer.component';
import { ChooseDownloadTypeComponent } from './components/choose-download-type/choose-download-type.component';
import { ClearFormButtonComponent } from './components/clear-form-button/clear-form-button.component';
import { CloseCaseButtonComponent } from './components/close-case/close-case-button.component';
import { CloseCaseDialogComponent } from './components/close-case/close-case-dialog/close-case-dialog.component';
import { DialogComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { CompareAmendmentOutputComponent } from './components/compare-amendment-output/compare-amendment-output.component';
import { CreateOutputButtonComponent } from './components/create-output-button/create-output-button.component';
import { CreateStickyNoteModalComponent } from './components/create-sticky-note-modal/create-sticky-note-modal.component';
import { DiagnosisConditionIconComponent } from './components/diagnosis-condition-icon/diagnosis-condition-icon.component';
import { DocumentUploadButtonComponent } from './components/document-upload/document-upload-button/document-upload-button.component';
import { DocumentUploadListComponent } from './components/document-upload/document-upload-list/document-upload-list.component';
import { DocumentUploadComponent } from './components/document-upload/document-upload/document-upload.component';
import { UploadModalComponent } from './components/document-upload/upload-modal/upload-modal.component';
import { DocumentationUploadLocalComponent } from './components/documentation-upload-local/documentation-upload-local.component';
import { DocumentationUploadComponent } from './components/documentation-upload/documentation-upload.component';
import { DynamicLearnerHeaderComponent } from './components/dynamic-learner-header/dynamic-learner-header.component';
import { IepLearnerInfoHeaderComponent } from './components/dynamic-learner-header/iep-learner-info-header/iep-learner-info-header.component';
import { IfspLearnerInfoHeaderComponent } from './components/dynamic-learner-header/ifsp-learner-info-header/ifsp-learner-info-header.component';
import { LearnerInfoHeaderComponent } from './components/dynamic-learner-header/learner-info-header/learner-info-header.component';
import { LearnerInfoComponent } from './components/dynamic-learner-header/learner-info/learner-info.component';
import { CaseEcoRatingComponent } from './components/eco-rating/case-eco-rating/case-eco-rating.component';
import { TransitionPlanningDeterminationComponent } from './components/eco-rating/transition-planning-determination/transition-planning-determination.component';
import { FiieConsentExpandedTextComponent } from './components/expanded-text/fiie-consent-expanded-text/fiie-consent-expanded-text.component';
import { SpecialFactorsExpandedTextComponent } from './components/expanded-text/special-factors-expanded-text/special-factors-expanded-text.component';
import { FormsControlModule } from './components/form/form-controls.module';
import { GlobalErrorModalComponent } from './components/global-error-modal/global-error-modal.component';
import { GlobalExpanderComponent } from './components/global-expander/global-expander.component';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { HelpTextSheetComponent } from './components/help-text-sheet/help-text-sheet.component';
import { HelpDirectionalTextComponent } from './components/help/help-directional-text/help-directional-text.component';
import { I3HelpComponent } from './components/i3-help/i3-help.component';
import { IfspIepResetDateComponent } from './components/ifsp-iep-reset-date/ifsp-iep-reset-date.component';
import { IncompleteDataReportComponent } from './components/incomplete-data-report/incomplete-data-report.component';
import { InlineEditComponent } from './components/inline-edit/inline-edit.component';
import { KeyValueLabelComponent } from './components/key-value-label/key-value-label.component';
import { LearnerCalendarComponent } from './components/learner-calendar/learner-calendar.component';
import { LocationFilterComponent } from './components/location-filter/location-filter.component';
import { MedicalDiagnosisTableComponent } from './components/medical-diagnosis-table/medical-diagnosis-table.component';
import { MeetingRollCallComponent } from './components/meeting-roll-call/meeting-roll-call.component';
import { MultiGraphViewModalComponent } from './components/multi-graph-view-modal/multi-graph-view-modal.component';
import { NavigateToEvalButtonComponent } from './components/navigate-to-eval-button/navigate-to-eval-button.component';
import { FamilyMemberComponent } from './components/new-child/family-member/family-member.component';
import { NewChildReadOnlyComponent } from './components/new-child/new-child-read-only/new-child-read-only.component';
import { NewChildComponent } from './components/new-child/new-child.component';
import { ReferralMedicalDiagnosisTableComponent } from './components/new-child/referral-reason-info-form/medical-diagnosis-table/referral-medical-diagnosis-table.component';
import { ReferralReasonInfoFormComponent } from './components/new-child/referral-reason-info-form/referral-reason-info-form.component';
import { ReferralSourceInfoFormComponent } from './components/new-child/referral-source-info-form/referral-source-info-form.component';
import { NonAchieveParticipantsComponent } from './components/non-achieve-participants/non-achieve-participants.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageAlertComponent } from './components/page-alert/page-alert.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageUnderConstructionComponent } from './components/page-under-construction/page-under-construction.component';
import { PageUnderEditHeaderComponent } from './components/page-under-edit-header/page-under-edit-header.component';
import { PageValidationModule } from './components/page-validation/page-validation.module';
import { PdfDraftViewerComponent } from './components/pdf-draft-viewer/pdf-draft-viewer.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PrintHeadComponent } from './components/print-head/print-head.component';
import { ProceduralSafeguardsListComponent } from './components/procedural-safeguards/procedural-safeguards-list.component';
import { ProgressIndicatorBarComponent } from './components/progress-indicator-bar/progress-indicator-bar.component';
import { MilestonesComponent } from './components/quantifiable-data/milestones/milestones.component';
import { QuantifiableDataComponent } from './components/quantifiable-data/quantifiable-data.component';
import { ScaleLevelsComponent } from './components/quantifiable-data/scale-levels/scale-levels.component';
import { ReopenCaseComponent } from './components/reopen-case/reopen-case.component';
import { SamlUnauthorizedScreenComponent } from './components/saml-unauthorized-screen/saml-unauthorized-screen.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SelectSignatoriesModule } from './components/select-signatories/select-signatories.module';
import { SharedIvrsUsersComponent } from './components/shared-ivrs-users/shared-ivrs-users.component';
import { SharedUsersComponent } from './components/shared-users/shared-users.component';
import { SignedFormOverviewComponent } from './components/signed-form-overview/signed-form-overview.component';
import { StethoscopeIconComponent } from './components/stethoscope-icon/stethoscope-icon.component';
import { TablePaginatorComponent } from './components/table-paginator/table-paginator.component';
import { TeamEffortIconComponent } from './components/team-effort-icon/team-effort-icon.component';
import { TeamMemberTableComponent } from './components/team-member-table/team-member-table.component';
import { TeamMemberComponent } from './components/team-member/team-member.component';
import { TestLinksComponent } from './components/test-links/test-links.component';
import { UnauthorizedScreenComponent } from './components/unauthorized-screen/unauthorized-screen.component';
import { UserManagementTogglerComponent } from './components/user-management-toggler/user-management-toggler.component';
import { UserTeamsComponent } from './components/user-teams/user-teams.component';
import { ValidatedIconComponent } from './components/validated-icon/validated-icon.component';
import { WeightMatrixScoreHistoryComponent } from './components/weight-matrix-score-history/weight-matrix-score-history.component';
import { AuthForPermissionsDirective } from './directives/auth-for-permissions.directive';
import { AuthForRolesDirective } from './directives/auth-for-roles.directive';
import { BusyButtonDirective, LoadingIconComponent } from './directives/busy-button.directive';
import { CaptureExpandDirective } from './directives/captureExpand.directive';
import { ConditionallyRequiredDirective } from './directives/conditionally-required.directive';
import { DoubleViewDirective } from './directives/double-view.directive';
import { EditViewDirective } from './directives/edit-view.directive';
import { EnabledOnlyDirective } from './directives/enabled-only.directive';
import { IepAmendmentsAttributeDirective, IepAmendmentsStructuralDirective } from './directives/iep-amendments.directive';
import { IfControlNameDirective } from './directives/if-control-name.directive';
import { PaginatorDataSourceDirective } from './directives/paginator-data-source.directive';
import { ReadOnlyViewDirective } from './directives/read-only-view.directive';
import { RemoveIfFeatureOffDirective } from './directives/remove-if-feature-off.directive';
import { TableWithSortDirective } from './directives/table-with-sort.directive';
import { TrackFormChangesDirective } from './directives/track-form-changes.directive';
import { AdditionalInformationModalComponent } from './modals/additional-information-modal/additional-information-modal.component';
import { DeleteConfirmationComponent } from './modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { ECPSearchModalComponent } from './modals/ecp-search-modal/ecp-search-modal.component';
import { FamilyProviderMemberSearchModalComponent } from './modals/family-provider-member-search-modal/family-provider-member-search-modal.component';
import { HelpModalComponent } from './modals/help-modal/help-modal.component';
import { ModifyExistingDocumentComponent } from './modals/modify-existing-document/modify-existing-document.component';
import { ParticipantSearchModalComponent } from './modals/participant-search/participant-search-modal.component';
import { RequestExcusalModalComponent } from './modals/request-excusal-modal/request-excusal-modal.component';
import { RescheduleMeetingModalComponent } from './modals/reschedule-meeting-modal/reschedule-meeting-modal.component';
import { SentenceStartersModalComponent } from './modals/sentence-starters-modal/sentence-starters-modal.component';
import { SignatureModalComponent } from './modals/signature-modal/signature-modal.component';
import { TeamMemberSearchModalComponent } from './modals/team-member-search-modal/team-member-search-modal.component';
import { TimeoutModalComponent } from './modals/timeout-modal/timeout-modal.component';
import { UploadDocumentationModalComponent } from './modals/upload-documentation-modal/upload-documentation-modal.component';
import { UserSearchComponent } from './modals/user-search/user-search.component';
import { UserTeamsCreateModalComponent } from './modals/user-teams-create-modal/user-teams-create-modal.component';
import { ViewMoreModalComponent } from './modals/view-more-modal/view-more-modal.component';
import { AbbreviationPipe } from './pipes/abbreviation.pipe';
import { AgeInMonthsPipe } from './pipes/age-in-months-pipe';
import { AssociatedStatePipe } from './pipes/associated-state.pipe';
import { BooleanEffectivePipe } from './pipes/boolean-effective.pipe';
import { BooleanYesNoPipe, NullableBooleanYesNoPipe } from './pipes/boolean-yesno.pipe';
import { CallbackPipe } from './pipes/callback.pipe';
import { CamelCaseWordsPipe } from './pipes/camel-case-words.pipe';
import { DobAgePipe } from './pipes/dob-age.pipe';
import { DobNoTzPipe } from './pipes/dob-no-tz.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FirstNamePossessivePipe } from './pipes/first-name-possessive.pipe';
import { FormControlPipe } from './pipes/form-control.pipe';
import { FormGroupPipe } from './pipes/form-group.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { NotApplicablePipe } from './pipes/not-applicable.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { PunctuationPipe } from './pipes/punctuation.pipe';
import { RemoveObsoleteTermsPipe } from './pipes/remove-obsolete-terms.pipe';
import { SafeImagePipe } from './pipes/safe-image.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { PrintPageComponent } from './print/print-page/print-page.component';
import { DhhAlertComponent } from './components/dhh-alert/dhh-alert.component';

@NgModule({
  declarations: [
    PageHeaderComponent,
    UserTeamsComponent,
    UserTeamsCreateModalComponent,
    TeamMemberComponent,
    TeamMemberSearchModalComponent,
    FamilyProviderMemberSearchModalComponent,
    ECPSearchModalComponent,
    HelpModalComponent,
    NewChildComponent,
    FamilyMemberComponent,
    ReferralReasonInfoFormComponent,
    ReferralMedicalDiagnosisTableComponent,
    PrintPageComponent,
    ReferralSourceInfoFormComponent,
    DialogComingSoonComponent,
    UploadDocumentationModalComponent,
    ScrollToTopComponent,
    CreateStickyNoteModalComponent,
    SafeImagePipe,
    BooleanYesNoPipe,
    NullableBooleanYesNoPipe,
    BooleanEffectivePipe,
    CallbackPipe,
    DiagnosisConditionIconComponent,
    DobAgePipe,
    DobNoTzPipe,
    AgeInMonthsPipe,
    GlobalErrorModalComponent,
    LearnerCalendarComponent,
    AreYouSureComponent,
    AreYouSureMemoModalComponent,
    InlineEditComponent,
    DocumentationUploadComponent,
    QuantifiableDataComponent,
    NotFoundComponent,
    CloseCaseButtonComponent,
    CloseCaseDialogComponent,
    NavigateToEvalButtonComponent,
    TruncatePipe,
    FirstNamePossessivePipe,
    SignatureModalComponent,
    OrdinalPipe,
    SentenceStartersModalComponent,
    HelpTextSheetComponent,
    ScaleLevelsComponent,
    MilestonesComponent,
    PhonePipe,
    ViewMoreModalComponent,
    MedicalDiagnosisTableComponent,
    PageAlertComponent,
    BankableFieldsComponent,
    PrintHeadComponent,
    ParticipantSearchModalComponent,
    ProceduralSafeguardsListComponent,
    FamilyMeetingListComponent,
    RescheduleMeetingModalComponent,
    BaseComponent,
    MeetingRollCallComponent,
    DynamicLearnerHeaderComponent,
    IfspLearnerInfoHeaderComponent,
    IepLearnerInfoHeaderComponent,
    LearnerInfoHeaderComponent,
    CompareAmendmentOutputComponent,
    NewChildReadOnlyComponent,
    HelpDirectionalTextComponent,
    I3HelpComponent,
    TeamMemberTableComponent,
    AddGoalProgressFormComponent,
    TestLinksComponent,
    SpecialFactorsExpandedTextComponent,
    FiieConsentExpandedTextComponent,
    CaptureExpandDirective,
    KeyValueLabelComponent,
    GlobalExpanderComponent,
    FormGroupPipe,
    FormControlPipe,
    PaginatorDataSourceDirective,
    TableWithSortDirective,
    JoinPipe,
    PluckPipe,
    StethoscopeIconComponent,
    ValidatedIconComponent,
    TeamEffortIconComponent,
    TimeoutModalComponent,
    TrackFormChangesDirective,
    ReopenCaseComponent,
    IncompleteDataReportComponent,
    IfControlNameDirective,
    EnabledOnlyDirective,
    ConditionallyRequiredDirective,
    BusyButtonDirective,
    LoadingIconComponent,
    DoubleViewDirective,
    EditViewDirective,
    ReadOnlyViewDirective,
    AnnualReviewQuestionnaireComponent,
    ChartViewerComponent,
    LearnerInfoComponent,
    IepAmendmentsStructuralDirective,
    IepAmendmentsAttributeDirective,
    TransitionPlanningDeterminationComponent,
    SignedFormOverviewComponent,
    RequestExcusalModalComponent,
    CreateOutputButtonComponent,
    NotApplicablePipe,
    CaseEcoRatingComponent,
    RemoveIfFeatureOffDirective,
    AuthForRolesDirective,
    PdfViewerComponent,
    AuthForPermissionsDirective,
    DeleteConfirmationComponent,
    WeightMatrixScoreHistoryComponent,
    UnauthorizedScreenComponent,
    SamlUnauthorizedScreenComponent,
    NonAchieveParticipantsComponent,
    IfspIepResetDateComponent,
    PageUnderEditHeaderComponent,
    CaseExitBannerComponent,
    TablePaginatorComponent,
    SharedUsersComponent,
    ClearFormButtonComponent,
    HealthCheckComponent,
    PunctuationPipe,
    MultiGraphViewModalComponent,
    DocumentUploadComponent,
    DocumentUploadButtonComponent,
    DocumentUploadListComponent,
    UploadModalComponent,
    CamelCaseWordsPipe,
    FilterPipe,
    AbbreviationPipe,
    AdditionalInformationModalComponent,
    AssociatedStatePipe,
    UserManagementTogglerComponent,
    RemoveObsoleteTermsPipe,
    LocationFilterComponent,
    PdfDraftViewerComponent,
    SharedIvrsUsersComponent,
    DocumentationUploadLocalComponent,
    UserSearchComponent,
    PageUnderConstructionComponent,
    PdfDraftViewerComponent,
    ChooseDownloadTypeComponent,
    ProgressIndicatorBarComponent,
    ModifyExistingDocumentComponent,
    DhhAlertComponent,
  ],
  imports: [
    CommonModule,
    AppMatModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    RouterModule,
    FormsControlModule,
    AngularSvgIconModule,
    HttpClientModule,
    VChartModule,
    SelectSignatoriesModule,
    PageValidationModule,
    ClipboardModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    LearnerInfoHeaderComponent,
    IepLearnerInfoHeaderComponent,
    IfspLearnerInfoHeaderComponent,
    DynamicLearnerHeaderComponent,
    PageHeaderComponent,
    UserTeamsComponent,
    UserTeamsCreateModalComponent,
    TeamMemberComponent,
    TeamMemberSearchModalComponent,
    FamilyProviderMemberSearchModalComponent,
    ECPSearchModalComponent,
    HelpModalComponent,
    NewChildComponent,
    FamilyMemberComponent,
    PrintPageComponent,
    DialogComingSoonComponent,
    DiagnosisConditionIconComponent,
    UploadDocumentationModalComponent,
    ScrollToTopComponent,
    CreateStickyNoteModalComponent,
    SafeImagePipe,
    BooleanYesNoPipe,
    CallbackPipe,
    NullableBooleanYesNoPipe,
    BooleanEffectivePipe,
    DobAgePipe,
    DobNoTzPipe,
    AgeInMonthsPipe,
    GlobalErrorModalComponent,
    LearnerCalendarComponent,
    InlineEditComponent,
    DocumentationUploadComponent,
    QuantifiableDataComponent,
    CloseCaseButtonComponent,
    CloseCaseDialogComponent,
    FirstNamePossessivePipe,
    NavigateToEvalButtonComponent,
    TruncatePipe,
    OrdinalPipe,
    PhonePipe,
    DatePipe,
    SentenceStartersModalComponent,
    FormsControlModule,
    ViewMoreModalComponent,
    MedicalDiagnosisTableComponent,
    PageAlertComponent,
    BankableFieldsComponent,
    ReferralMedicalDiagnosisTableComponent,
    PrintHeadComponent,
    ParticipantSearchModalComponent,
    ProceduralSafeguardsListComponent,
    FamilyMeetingListComponent,
    RescheduleMeetingModalComponent,
    CompareAmendmentOutputComponent,
    NewChildReadOnlyComponent,
    HelpDirectionalTextComponent,
    I3HelpComponent,
    TeamMemberTableComponent,
    AddGoalProgressFormComponent,
    TestLinksComponent,
    CaptureExpandDirective,
    KeyValueLabelComponent,
    GlobalExpanderComponent,
    FormGroupPipe,
    FormControlPipe,
    PaginatorDataSourceDirective,
    TableWithSortDirective,
    JoinPipe,
    PluckPipe,
    StethoscopeIconComponent,
    ValidatedIconComponent,
    TeamEffortIconComponent,
    TimeoutModalComponent,
    TrackFormChangesDirective,
    ReopenCaseComponent,
    IncompleteDataReportComponent,
    IfControlNameDirective,
    EnabledOnlyDirective,
    ConditionallyRequiredDirective,
    BusyButtonDirective,
    DoubleViewDirective,
    EditViewDirective,
    ReadOnlyViewDirective,
    AnnualReviewQuestionnaireComponent,
    ChartViewerComponent,
    IepAmendmentsStructuralDirective,
    IepAmendmentsAttributeDirective,
    TransitionPlanningDeterminationComponent,
    CaseEcoRatingComponent,
    SignedFormOverviewComponent,
    SelectSignatoriesModule,
    PageValidationModule,
    RequestExcusalModalComponent,
    CreateOutputButtonComponent,
    NotApplicablePipe,
    RemoveIfFeatureOffDirective,
    AuthForRolesDirective,
    AuthForPermissionsDirective,
    WeightMatrixScoreHistoryComponent,
    NonAchieveParticipantsComponent,
    IfspIepResetDateComponent,
    PageUnderEditHeaderComponent,
    CaseExitBannerComponent,
    TablePaginatorComponent,
    SharedUsersComponent,
    ClearFormButtonComponent,
    HealthCheckComponent,
    PunctuationPipe,
    DocumentUploadComponent,
    DocumentUploadButtonComponent,
    DocumentUploadListComponent,
    UploadModalComponent,
    CamelCaseWordsPipe,
    FilterPipe,
    AbbreviationPipe,
    AssociatedStatePipe,
    AdditionalInformationModalComponent,
    UserManagementTogglerComponent,
    RemoveObsoleteTermsPipe,
    LocationFilterComponent,
    SharedIvrsUsersComponent,
    DocumentationUploadLocalComponent,
    UserSearchComponent,
    PageUnderConstructionComponent,
    ProgressIndicatorBarComponent,
    ModifyExistingDocumentComponent,
  ],
})
export class SharedModule {}
