import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscriber } from 'rxjs';
import { AppPermissions } from '../permissions';
import { CaseListItem, CaseSummary, IntakeType } from '../shared/models/case';
import { OperationResult } from '../shared/models/operation-result';
import { Role } from '../shared/models/role';
import { User } from '../shared/models/user';
import { UserRole } from '../shared/models/user-role';
import { UserRoleUpdate } from '../shared/models/user-role-update';
import { AchieveConfigService } from '../shared/services/achieve-config-service/achieve-config.service';
import { ForgotPasswordViewModel, LoginModel, RegisterModel, ResetPasswordViewModel } from './auth-models';
import { AuthUser } from './auth-user';
import { EarlyAccessQuestionnaireInvite } from './family-member-registration/models/early-access-questionnaire-invite';
import { FamilyMemberAdd, FamilyMemberRegistration } from './family-member-registration/models/family-member-registration';
import { LearnerRegistration } from './learner-registration/models/learner-registration';

export enum ClaimTypes {
  AeaDataManager = 'AeaDataManager',
  AeaEdit = 'AeaEdit',
  AeaReadOnly = 'AeaReadOnly',
  ChscUser = 'ChscUser',
  ChscZeroToThree = 'ChscZeroToThree',
  DmpsAdmin = 'DmpsAdmin',
  DmpsUser = 'DmpsUser',
  DmpsZeroToThree = 'DmpsZeroToThree',
  LeaProviderEdit = 'LeaProviderEdit',
  EligibleFamily = 'EligibleFamily',
  Family = 'Family',
  ServiceCoordinator = 'ServiceCoordinator',
  SuperAdmin = 'SuperAdmin',
  UberAdmin = 'UberAdmin',
  WeightedMatrixEdit = 'WeightedMatrixEdit',
  WeightedMatrixView = 'WeightedMatrixView',
  StateWideSearch = 'StateWideSearch',
}

export enum Roles {
  SuperAdmin = 'SuperAdmin',
  StateWideEdit = 'StateWideEdit',
  StateWideReadOnly = 'StateWideReadOnly',
  AeaEdit = 'AeaEdit',
  AeaReadOnly = 'AeaReadOnly',
  ServiceCoordinator = 'ServiceCoordinator',
  LeaDistrictReadOnly = 'LeaDistrictReadOnly',
  LeaBuildingEdit = 'LeaBuildingEdit',
  LeaBuildingReadOnly = 'LeaBuildingReadOnly',
  LeaNonPublicBuildingReadOnly = 'LeaNonPublicBuildingReadOnly',
  LeaProviderEdit = 'LeaProviderEdit',
  Family = 'Family',
  ACHIEVEDataLead = 'ACHIEVEDataLead',
  ACHIEVEDataTechnician = 'ACHIEVEDataTechnician',
  TeamMember = 'TeamMember',
  UberAdmin = 'UberAdmin',
  ReportingOnly = 'ReportingOnly',
  FamilyMemberEdit = 'FamilyMemberEdit',
  FamilyMemberReadOnly = 'FamilyMemberReadOnly',
  OutOfStatePlacementAeaAdministrator = 'OutOfStatePlacementAeaAdministrator',
  OutOfStatePlacementLeaAdministrator = 'OutOfStatePlacementLeaAdministrator',
  ImplementationPlanManagement = 'ImplementationPlanManagement',
  ImplementationPlanProgressMonitoring = 'ImplementationPlanProgressMonitoring',
  ImplementationPlanReporting = 'ImplementationPlanReporting',
  ImplementationPlanViewOnly = 'ImplementationPlanViewOnly',
  EarlyACCESSFamilyEngagementReporting = 'EarlyACCESSFamilyEngagementReporting',
  SpecialEducationFamilyEngagementReporting = 'SpecialEducationFamilyEngagementReporting',
  ELAAAdministrator = 'ELAAAdministrator',
  ELAAReporting = 'ELAAReporting',
  SpecialEducationFamilyEngagementBulkNotifications = 'SpecialEducationFamilyEngagementBulkNotifications',
  LearnerEdit = 'LearnerEdit',
  LearnerReadOnly = 'LearnerReadOnly',
  VRReadOnly = 'VRReadOnly',
  VRAOAdministrator = 'VRAOAdministrator',
  VRStateWideAdministrator = 'VRStateWideAdministrator',
  StateEarlyACCESSDataAdministrator = 'StateEarlyACCESSDataAdministrator',
  AEAEarlyACCESSDataUser = 'AEAEarlyACCESSDataUser',
  FocusedMonitoringInquiryOwner = 'FocusedMonitoringInquiryOwner',
  FocusedMonitoringGeneralUser = 'FocusedMonitoringGeneralUser',
  FocusedMonitoringReadOnlyUser = 'FocusedMonitoringReadOnlyUser',
  LeaUserManager = 'LeaUserManager',
  FITCSAReporting = 'FITCSAReporting',
  ILOTReporting = 'ILOTReporting',
  DataReviewProtocolManagement = 'DataReviewProtocolManagement',
  DataReviewProtocolViewOnly = 'DataReviewProtocolViewOnly',
  DeComplaintInvestigatorReadOnlyUser = 'DeComplaintInvestigatorReadOnlyUser',
  GeneralEducation = 'GeneralEducation',
  EquityStateAdministrator = 'EquityStateAdministrator',
  EquityDistrictAdministrator = 'EquityDistrictAdministrator',
  EquityViewOnly = 'EquityViewOnly',
  EquitySigDisProOnly = 'EquitySigDisProOnly',
  DisputeResolutionInquiryLogEntry = 'DisputeResolutionInquiryLogEntry',
  DisputeResolutionInquiryLogReadOnly = 'DisputeResolutionInquiryLogReadOnly',
  CorrectiveActionDEAdministrator = 'CorrectiveActionDEAdministrator',
  CorrectiveActionReviewer = 'CorrectiveActionReviewer',
  CorrectiveActionAgencyRespondent = 'CorrectiveActionAgencyRespondent',
  CorrectiveActionReadOnly = 'CorrectiveActionReadOnly',
  AeaSpecialEducationDirector = 'AeaSpecialEducationDirector',
  DHHAudiometristGeneral = 'DHHAudiometristGeneral',
  DHHAudiologistGeneral = 'DHHAudiologistGeneral',
  DHHLimitedAccess = 'DHHLimitedAccess',
  DHHReadOnly = 'DHHReadOnly',
  DHHSchedulingAdministration = 'DHHSchedulingAdministration',
  DELegal = 'DELegal',
  Complainant = 'Complainant',
  StateComplaintInvestigator = 'StateComplaintInvestigator',
  Respondent = 'Respondent',
  OtherCaseContacts = 'OtherCaseContacts',
  DEViewOnly = 'DEViewOnly',
  CountDashboardEditUser = 'CountDashboardEditUser',
  CountDashboardCommunicationsOnlyUser = 'CountDashboardCommunicationsOnlyUser',
  ParentDistrictValidation = 'ParentDistrictValidation',
  ParentBuildingValidation = 'ParentBuildingValidation',
}

const SuperAdminPermissions: AppPermissions[] = [
  AppPermissions.DeletePwn,
  AppPermissions.CancelIEPAmendment,
  AppPermissions.CancelIFSPModification,
  AppPermissions.CancelReevaluation,
  AppPermissions.CancelAnnualPeriodicReview,
  AppPermissions.CancelFBA,
  AppPermissions.CancelBIP,
];

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: AuthUser;
  redirectUrl = '';
  private nonInterceptedHttpClient: HttpClient;

  private authUserChangeSource = new Subject<void>();
  authUserChanged$ = this.authUserChangeSource.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private handler: HttpBackend,
    private achieveConfigService: AchieveConfigService
  ) {
    this.nonInterceptedHttpClient = new HttpClient(handler);
  }

  get userId() {
    return !!this.user.id;
  }

  get isAuthenticated() {
    return !!this.user;
  }
  get isAchieveUser() {
    return this.user?.discriminator === 'User';
  }
  get isUberAdmin() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.UberAdmin);
  }

  get isSuperAdmin() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.SuperAdmin);
  }

  get isAeaEdit() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.AeaEdit);
  }

  get isStateWideEdit() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.StateWideEdit);
  }

  get isStateUser() {
    return !!this.user?.rolePermissions.find(
      (c) => c.role === Roles.StateWideEdit || c.role === Roles.StateWideReadOnly || c.role === Roles.DeComplaintInvestigatorReadOnlyUser
    );
  }

  get isCountDashboardAdmin() {
    return this.user?.claims?.some(
      (element) => element.type === 'individual-permission' && element.value === AppPermissions.CountAdministrator
    );
  }

  get isCountDashboardEditUser() {
    return this.user?.rolePermissions.some((element) => element.role === Roles.CountDashboardEditUser);
  }

  get isCountDashboardCommunicationsOnlyUser() {
    return this.user?.rolePermissions.some((element) => element.role === Roles.CountDashboardCommunicationsOnlyUser);
  }

  get isCorrectiveActionUser() {
    return (
      this.isSuperAdmin ||
      this.isUberAdmin ||
      this.isCorrectiveActionDEAdministrator ||
      this.isCorrectiveActionReviewer ||
      this.isCorrectiveActionAgencyRespondent ||
      this.isCorrectiveActionReadOnly
    );
  }

  get isCorrectiveActionStateWide() {
    const reviewerStateWide = this.user?.rolePermissions?.find(
      (c) => c.role === Roles.CorrectiveActionReviewer && c.hasOptionalAssignments && c.optionalAssignments?.length === 0
    );
    const readOnlyStateWide = this.user?.rolePermissions?.find(
      (c) => c.role === Roles.CorrectiveActionReadOnly && c.hasOptionalAssignments && c.optionalAssignments?.length === 0
    );
    return this.isCorrectiveActionDEAdministrator || !!reviewerStateWide || !!readOnlyStateWide;
  }

  get isCorrectiveActionReviewerOrRespondent() {
    return this.isCorrectiveActionReviewer || this.isCorrectiveActionAgencyRespondent;
  }

  get isCorrectiveActionDEAdministrator() {
    return !!this.user?.rolePermissions?.find((c) => c.role === Roles.CorrectiveActionDEAdministrator);
  }

  get isCorrectiveActionReviewer() {
    return !!this.user?.rolePermissions?.find((c) => c.role === Roles.CorrectiveActionReviewer);
  }

  get isCorrectiveActionAgencyRespondent() {
    return !!this.user?.rolePermissions?.find((c) => c.role === Roles.CorrectiveActionAgencyRespondent);
  }

  get isCorrectiveActionReadOnly() {
    return !!this.user?.rolePermissions?.find((c) => c.role === Roles.CorrectiveActionReadOnly);
  }

  get isEquityUser() {
    return this.isSuperAdmin || this.isEquityStateAdministrator || this.isEquityDistrictAdministrator || this.isEquityViewOnly;
  }

  get isEquityStateAdministrator() {
    return !!this.user?.rolePermissions.find((x) => x.role === Roles.EquityStateAdministrator);
  }

  get isEquityDistrictAdministrator() {
    return !!this.user?.rolePermissions.find((x) => x.role === Roles.EquityDistrictAdministrator);
  }

  get isEquityViewOnly() {
    return !!this.user?.rolePermissions.find((x) => x.role === Roles.EquityViewOnly);
  }

  get isEquitySigDisProOnly() {
    return !!this.user?.rolePermissions.find((x) => x.role === Roles.EquitySigDisProOnly);
  }

  get isEquitySigDisProStatewide() {
    return (
      !!this.user?.rolePermissions.find((x) => x.role === Roles.EquitySigDisProOnly && x.optionalAssignments.length === 0) ||
      !!this.user?.rolePermissions.find((x) => x.role === Roles.EquityViewOnly && x.optionalAssignments.length === 0) ||
      this.isEquityStateAdministrator ||
      this.isSuperAdmin
    );
  }

  get isEquitySigDisProDistrictOnly() {
    return this.user?.rolePermissions.some(
      (x) =>
        (x.role === Roles.EquitySigDisProOnly || x.role === Roles.EquityDistrictAdministrator) &&
        x.optionalAssignments.some((y) => y.locationType.name === 'LEA')
    );
  }

  get isDataLead() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.ACHIEVEDataLead);
  }

  isRoleIn(roleName: string, locationUid: string) {
    const role = this.user?.selectivePermissions?.find((c) => c.role === roleName);
    if (!role) return false;

    return !!role.assignments?.some((x) => x.locationUid === locationUid);
  }

  isDataLeadIn(locationUid: string) {
    return this.isRoleIn(Roles.ACHIEVEDataLead, locationUid);
  }

  get isDataManager() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.AeaDataManager);
  }

  get isDmpsAdmin() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.DmpsAdmin);
  }

  get isDmpsUser() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.DmpsUser);
  }

  get isChscUser() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.ChscUser);
  }

  get isDmpsZeroToThree() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.DmpsZeroToThree);
  }

  get isChscZeroToThree() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.ChscZeroToThree);
  }

  get isServiceCoordinator() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.ServiceCoordinator);
  }

  get isAchieveDataLead() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.ACHIEVEDataLead);
  }

  get isDataTechnician() {
    return this.user?.rolePermissions.some((c) => c.role === Roles.ACHIEVEDataTechnician);
  }

  get isLeaUserManager() {
    return this.user?.rolePermissions.some((c) => c.role === Roles.LeaUserManager);
  }

  get isLeaUserManagerOnly() {
    return (
      this.user?.rolePermissions.some((c) => c.role === Roles.LeaUserManager) &&
      !this.user?.rolePermissions.some((c) => c.role !== Roles.LeaUserManager)
    );
  }

  get isReportingOnly() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.ReportingOnly);
  }

  get isParentDistrictValidation() {
    return this.user?.rolePermissions.some((c) => c.role === Roles.ParentDistrictValidation);
  }

  get isParentBuildingValidation() {
    return this.user?.rolePermissions.some((c) => c.role === Roles.ParentBuildingValidation);
  }

  get isImplementationPlanManagement() {
    return (
      this.isUberAdmin ||
      this.isSuperAdmin ||
      !!this.isAllowed(
        AppPermissions.CreatePlanPushToCASAImplementationPlan,
        AppPermissions.EditExistingImplementationPlan,
        AppPermissions.PrintImplementationPlanOutput
      ) ||
      !!this.isAllowedByPermissionAlone(
        AppPermissions.CreatePlanPushToCASAImplementationPlan,
        AppPermissions.EditExistingImplementationPlan,
        AppPermissions.PrintImplementationPlanOutput
      )
    );
  }

  get isImplementationPlanProgressMonitoring() {
    return (
      this.isUberAdmin ||
      this.isSuperAdmin ||
      !!this.isAllowed(
        AppPermissions.ViewProgressMonitoringEntriesImplementationPlan,
        AppPermissions.AddProgressMonitoringEntriesImplementationPlan,
        AppPermissions.EditProgressMonitoringEntriesImplementationPlan,
        AppPermissions.ViewFinalizedImplementationPlan
      ) ||
      !!this.isAllowedByPermissionAlone(
        AppPermissions.ViewProgressMonitoringEntriesImplementationPlan,
        AppPermissions.AddProgressMonitoringEntriesImplementationPlan,
        AppPermissions.EditProgressMonitoringEntriesImplementationPlan,
        AppPermissions.ViewFinalizedImplementationPlan
      )
    );
  }

  get isImplementationPlanReporting() {
    return (
      this.isUberAdmin ||
      this.isSuperAdmin ||
      !!this.isAllowed(AppPermissions.ReportingImplementationPlan, AppPermissions.ViewImplementationPlan) ||
      !!this.isAllowedByPermissionAlone(AppPermissions.ReportingImplementationPlan, AppPermissions.ViewImplementationPlan)
    );
  }

  get isImplementationPlanViewOnly() {
    return this.isUberAdmin || this.isSuperAdmin || !!this.user?.rolePermissions.find((c) => c.role === Roles.ImplementationPlanViewOnly);
  }

  get isStateEarlyACCESSDataAdministrator() {
    return this.isSuperAdmin || !!this.user?.rolePermissions.find((c) => c.role === Roles.StateEarlyACCESSDataAdministrator);
  }

  get isAEAEarlyACCESSDataUser() {
    return this.isSuperAdmin || !!this.user?.rolePermissions.find((c) => c.role === Roles.AEAEarlyACCESSDataUser);
  }

  get isEarlyACCESSFamilyEngagementReporting() {
    return this.isSuperAdmin || !!this.user?.rolePermissions.find((u) => u.role === Roles.EarlyACCESSFamilyEngagementReporting);
  }

  get isSpecialEducationFamilyEngagementReporting() {
    return (
      this.isSuperAdmin ||
      !!this.isAllowed(AppPermissions.SpecialEducationFamilyEngagementReporting) ||
      !!this.isAllowedByPermissionAlone(AppPermissions.SpecialEducationFamilyEngagementReporting)
    );
  }

  get isELAAAdministrator() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.ELAAAdministrator);
  }

  get isELAAReporting() {
    return !!this.isAllowed(AppPermissions.ELAAReporting) || !!this.isAllowedByPermissionAlone(AppPermissions.ELAAReporting);
  }

  get isIfspSideOfWall() {
    return (
      this.isSuperAdmin ||
      !!this.user?.rolePermissions.find((u) => u.permissions.some((p) => p === AppPermissions.ViewIFSP || p === AppPermissions.EditIFSP))
    );
  }

  get hasNoPartBCases() {
    return !!this.user?.rolePermissions.find(
      (c) =>
        c.role === ClaimTypes.LeaProviderEdit ||
        c.role === ClaimTypes.ServiceCoordinator ||
        c.role === ClaimTypes.SuperAdmin ||
        c.role === ClaimTypes.Family ||
        c.role === ClaimTypes.ChscUser ||
        c.role === ClaimTypes.DmpsUser
    );
  }

  get IsReadOnly() {
    return !!this.user?.rolePermissions.find(
      (c) =>
        c.role === Roles.AeaReadOnly ||
        c.role === Roles.LeaBuildingReadOnly ||
        c.role === Roles.LeaDistrictReadOnly ||
        c.role === Roles.LeaNonPublicBuildingReadOnly ||
        c.role === Roles.StateWideReadOnly ||
        c.role === Roles.DeComplaintInvestigatorReadOnlyUser ||
        c.role === Roles.GeneralEducation
    );
  }

  get isEligibleFamily() {
    return !!this.user?.rolePermissions.find((c) => c.role === ClaimTypes.EligibleFamily);
  }

  get isAeaStaff() {
    return !!this.user?.rolePermissions.find(
      (c) => c.role === ClaimTypes.AeaDataManager || c.role === ClaimTypes.AeaEdit || c.role === ClaimTypes.AeaReadOnly
    );
  }

  get isVrUser() {
    return this.isAllowedStatewide(AppPermissions.VRUser);
  }
  get isInteractiveAccessUser() {
    return this.user?.specificPermissions?.some(
      (specificPermission) =>
        specificPermission?.permission === AppPermissions.Respondent ||
        specificPermission?.permission === AppPermissions.Complainant ||
        specificPermission?.permission === AppPermissions.OtherCaseContacts
    );
  }

  canViewStateComplaintCase(caseId: string) {
    return (
      this.user?.rolePermissions.some(
        (c) => c.role === Roles.DELegal || c.role === Roles.DEViewOnly || c.role === Roles.StateComplaintInvestigator
      ) ||
      this.isAllowedBySpecificPermissions(caseId, AppPermissions.Respondent) ||
      this.isAllowedBySpecificPermissions(caseId, AppPermissions.Complainant) ||
      this.isAllowedBySpecificPermissions(caseId, AppPermissions.OtherCaseContacts)
    );
  }

  canShareTransferVrCase() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.VRStateWideAdministrator || c.role === Roles.VRAOAdministrator);
  }

  get isLeaStaff() {
    return !!this.user?.rolePermissions.find(
      (c) => c.role === Roles.LeaBuildingReadOnly || c.role === Roles.LeaDistrictReadOnly || c.role === Roles.LeaNonPublicBuildingReadOnly
    );
  }

  get canAddFamilyContactLog() {
    return this.isAllowed(AppPermissions.FamilyContactLogAdd) || this.isSuperAdmin;
  }

  get isFamily() {
    return !!this.user?.rolePermissions.find(
      (c) => c.role === ClaimTypes.Family || c.role === Roles.FamilyMemberEdit || c.role === Roles.FamilyMemberReadOnly
    );
  }

  get isLearner() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.LearnerEdit || c.role === Roles.LearnerReadOnly);
  }

  get isPortalUser() {
    return !!this.user?.rolePermissions.find(
      (c) =>
        c.role === Roles.FamilyMemberEdit ||
        c.role === Roles.FamilyMemberReadOnly ||
        c.role === Roles.LearnerEdit ||
        c.role === Roles.LearnerReadOnly
    );
  }

  get isLearnerUser() {
    return this.user?.discriminator === 'LearnerUser';
  }

  get isPortalUserEdit() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.FamilyMemberEdit || c.role === Roles.LearnerEdit);
  }

  get isFocusedMonitoringInquiryOwner() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.FocusedMonitoringInquiryOwner) || this.isSuperAdmin;
  }

  get isFocusedMonitoringGeneralUser() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.FocusedMonitoringGeneralUser);
  }

  get isFocusedMonitoringReadOnlyUser() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.FocusedMonitoringReadOnlyUser);
  }

  get isOospUser() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.OutOfStatePlacementAeaAdministrator);
  }

  get isOospApprover() {
    return this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.OosPlacementApproval);
  }

  get isFamilyEngagementAdministrator() {
    return (
      this.isSuperAdmin ||
      this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.FamilyEngagementAdministrator)
    );
  }

  get hasFamilyClaim() {
    return this.user?.claims?.some((c) => c.type === 'family');
  }

  get isWeightedMatrixEditor() {
    return !!this.user?.claims.find((c) => c.value === ClaimTypes.WeightedMatrixEdit);
  }

  get isWeightedMatrixViewer() {
    return !!this.user?.claims.find((c) => c.value === ClaimTypes.WeightedMatrixView);
  }

  get isGeneralEducation() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.GeneralEducation);
  }

  get isAdhocReportViewer() {
    return this.isAdhocPhase1ReportViewer || this.isAdhocPhase2ReportViewer || this.isAdhocDhhReportViewer;
  }

  get isAdhocPhase1ReportViewer() {
    return this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.AdhocReportingView);
  }

  get isAdhocPhase2ReportViewer() {
    return this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.AdhocPhase2ReportingView);
  }

  get isAdhocDhhReportViewer() {
    return this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.AdhocDhhReportingView);
  }

  get isInquiryLogEditor() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DisputeResolutionInquiryLogEntry);
  }

  get isInquiryLogViewer() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DisputeResolutionInquiryLogReadOnly);
  }

  get isDhhUser() {
    return this.isDhhAudiometrist || this.isDhhAudiologist || this.isDhhLimitedAccess || this.isDhhReadOnly || this.isDhhScheduler;
  }

  get isUserWithDhhOnlyRoles() {
    const dhhOnlyRoles = [
      Roles.DHHAudiometristGeneral.toString(),
      Roles.DHHAudiologistGeneral.toString(),
      Roles.DHHLimitedAccess.toString(),
      Roles.DHHReadOnly.toString(),
      Roles.DHHSchedulingAdministration.toString(),
    ];

    return this.user?.rolePermissions?.length > 0 && !!this.user?.rolePermissions.every((role) => dhhOnlyRoles.includes(role.role));
  }

  get isDhhAudiometrist() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DHHAudiometristGeneral);
  }

  get isDhhAudiologist() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DHHAudiologistGeneral);
  }

  get isDhhLimitedAccess() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DHHLimitedAccess);
  }

  get isDhhReadOnly() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DHHReadOnly);
  }

  get isDhhScheduler() {
    return !!this.user?.rolePermissions.find((u) => u.role === Roles.DHHSchedulingAdministration);
  }

  isAllowedByRoles(roles: Roles[]) {
    return this.user?.rolePermissions?.some((x) => roles.includes(x.role as Roles));
  }

  isReadOnlyByCaseId(caseId: string, permission: AppPermissions) {
    let canView = false;
    if (this.user?.caseOwnerCases.includes(caseId)) {
      canView = true;
      return canView;
    }
    this.user?.rolePermissions.forEach((rp) => {
      if (!rp.isReadOnly && rp.permissions.includes(permission)) {
        canView = true;
      }
    });

    this.user?.selectivePermissions.forEach((sp) => {
      if (!sp.isReadOnly && sp.permissions.includes(permission) && sp.cases.includes(caseId)) {
        canView = true;
      }
    });
    return canView;
  }

  /**
   * Checks to see if the user hold ANY of the passed in permissions
   * @param perms one or more app permissions
   */
  isAllowed(...perms: AppPermissions[]) {
    return this.isAllowedWithAdditionalRequirements(null, null, ...perms);
  }

  isAllowedStatewide(...perms: AppPermissions[]): boolean {
    if (!this.user) return false;

    return this.user.rolePermissions.some((rp) => rp.permissions.some((p) => perms.includes(p)));
  }

  isAllowedInLocation(locationUid: string, ...perms: AppPermissions[]): boolean {
    if (!this.user) return false;

    //Check is permitted by state-wide permissions
    if (this.isAllowedStatewide(...perms)) return true;

    //No state-wide permission exists, now check location based
    return this.user.selectivePermissions.some(
      (sp) => sp.permissions.some((p) => perms.includes(p)) && sp.assignments.some((a) => a.locationUid === locationUid)
    );
  }

  private isAllowedByCaseOwnerSuperAdmin(caseId?: string, ...perms: AppPermissions[]) {
    if (
      this.isSuperAdmin ||
      this.isUberAdmin ||
      (!!caseId && this.user?.caseOwnerCases.includes(caseId) && !perms.some((perm) => SuperAdminPermissions.includes(perm)))
    ) {
      return true;
    }
    return false;
  }

  isAllowedWithAdditionalRequirements(additionalRequirement?: Map<string, string[]>, caseId?: string, ...perms: AppPermissions[]) {
    // Case owner and super admin gets perm
    if (this.isAllowedByCaseOwnerSuperAdmin(caseId, ...perms)) {
      return true;
    }
    let allowed = false;
    if (!additionalRequirement) {
      allowed = this.isAllowedStatewide(...perms);
    } else {
      if (!additionalRequirement.has('CaseOwner') && !additionalRequirement.has('TeamMember')) {
        throw new Error('Additional Requirement has unknown requirement key');
      }
      this.user?.rolePermissions.forEach((rolePermission) => {
        if (Array.from(additionalRequirement.values()).flat().includes(rolePermission.role)) {
          allowed =
            perms.some((appPermission) => rolePermission.permissions.includes(appPermission)) &&
            !!caseId &&
            (additionalRequirement.has('CaseOwner')
              ? this.user?.caseOwnerCases?.includes(caseId)
              : this.user?.teamMemberCases?.includes(caseId));
        } else {
          if (this.user?.rolePermissions?.some((perm) => perm.permissions.some((roleAppPermission) => perms.includes(roleAppPermission)))) {
            allowed = true;
          }
        }
        if (allowed) {
          return allowed;
        }
      });
    }
    return allowed;
  }

  private isAllowedByCaseIdWithoutAdditionalRequirement(caseId?: string, ...perms: AppPermissions[]): boolean {
    // No location required, just perm
    if (this.user?.rolePermissions?.some((perm) => perm.permissions.some((roleAppPermission) => perms.includes(roleAppPermission)))) {
      return true;
    }

    // Permission and either Assignment (location) or Part of the case
    if (
      this.user?.selectivePermissions?.some(
        (selectivePerm) =>
          (selectivePerm.cases.includes(caseId) || this.user?.teamMemberCases.includes(caseId)) &&
          perms.some((perm) => selectivePerm.permissions.includes(perm))
      )
    ) {
      return true;
    }
    return false;
  }

  isAllowedByPermissionAlone(...perms: AppPermissions[]): boolean {
    // No location required, just perm
    return (
      !!this.user?.selectivePermissions?.some((selectivePerm) => perms.some((perm) => selectivePerm.permissions.includes(perm))) ||
      !!this.user?.rolePermissions?.some((rolePerm) => rolePerm.permissions?.some((perm) => perms.includes(perm)))
    );
  }

  isAllowedBySpecificPermissions(id: string, perm: AppPermissions): boolean {
    return this.user?.specificPermissions?.some(
      (specificPermission) => specificPermission?.id?.toLowerCase() === id?.toLowerCase() && specificPermission?.permission === perm
    );
  }

  isAllowedByCaseId(caseId?: string, additionalRequirement?: Map<string, string[]>, ...perms: AppPermissions[]): boolean {
    // Case owner and super admin gets perm
    if (this.isAllowedByCaseOwnerSuperAdmin(caseId, ...perms)) {
      return true;
    }

    let permAllowed = false;
    if (!additionalRequirement) {
      permAllowed = this.isAllowedByCaseIdWithoutAdditionalRequirement(caseId, ...perms);
      if (permAllowed) {
        return permAllowed;
      }
    } else {
      if (!additionalRequirement.has('CaseOwner') && !additionalRequirement.has('TeamMember')) {
        throw new Error('Additional Requirement has unknown requirement key');
      }
      // Loop through the roles and match additional requirement, it no match regular perm
      this.user?.selectivePermissions.forEach((selectivePermission) => {
        if (Array.from(additionalRequirement.values()).flat().includes(selectivePermission.role)) {
          permAllowed =
            permAllowed ||
            (perms.some((perm) => selectivePermission.permissions.includes(perm)) &&
              (additionalRequirement.has('CaseOwner')
                ? this.user?.caseOwnerCases?.includes(caseId)
                : this.user?.teamMemberCases?.includes(caseId)));
        } else {
          permAllowed =
            permAllowed ||
            ((selectivePermission.cases.includes(caseId) || this.user?.teamMemberCases.includes(caseId)) &&
              perms.some((perm) => selectivePermission.permissions.includes(perm)));
        }

        if (permAllowed) {
          return permAllowed;
        }
      });

      if (permAllowed) {
        return permAllowed;
      }

      permAllowed = this.isAllowedWithAdditionalRequirements(additionalRequirement, caseId, ...perms);
      if (permAllowed) {
        return permAllowed;
      }
    }
    return permAllowed;
  }

  isAllowedByPortalLearnerId(learnerId: string): boolean {
    return this.user?.portalLearners?.includes(learnerId);
  }

  isCaseTeamMember(caseId: string): boolean {
    return this.user?.teamMemberCases.includes(caseId);
  }

  isCaseOwner(caseId: string): boolean {
    return this.user?.caseOwnerCases.includes(caseId);
  }

  isCaseUser(caseModel: CaseSummary | CaseListItem): boolean {
    return !!caseModel?.caseUsers.find((cu) => cu.id === this.user?.id);
  }

  canEditCase(caseModel: CaseSummary | CaseListItem) {
    return (
      this.isAllowedByCaseId(caseModel.id, null, AppPermissions.EditAllCases) ||
      (this.isAllowedByCaseId(caseModel.id, null, AppPermissions.EditOwnCases) && this.isCaseUser(caseModel))
    );
  }

  authUserChanged() {
    this.authUserChangeSource.next();
  }

  get canInitiateDisabilitySuspect() {
    let hasIndividualPermission = false;
    this.user?.claims.forEach((element) => {
      if (element.type === 'individual-permission' && element.value === AppPermissions.InitiateDisabilitySuspect) {
        hasIndividualPermission = true;
      }
    });
    return this.isAchieveDataLead || this.isSuperAdmin || hasIndividualPermission;
  }

  get canEditSurrogates() {
    return this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.SurrogateInfoEdit);
  }

  get canAssignPermissions() {
    return this.user?.claims?.some((x) => x.type === 'individual-permission' && x.value === AppPermissions.AssignPermissions);
  }

  login(creds: LoginModel): Observable<AuthUser> {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('/api/login', creds).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Login failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  ivrsLogin(creds: LoginModel): Observable<AuthUser> {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('/api/ivrs-login', creds).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Login failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  interactiveAccessLogin(creds: LoginModel): Observable<AuthUser> {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('/api/interactive-access-login', creds).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Login failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  samlLogin(creds: LoginModel): Observable<AuthUser> {
    this.user = null;
    // TODO: lookup by user name
    const saml = SAML;
    const form = new FormData();
    form.append('SAMLResponse', saml.replace('***userid***', creds.email));
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('/api/saml-login', form).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Login failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  register(creds: RegisterModel): Observable<AuthUser> {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('/api/register', creds).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Registration failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  navigateToLogin() {
    if (this.achieveConfigService.settings.loginUrl) {
      window.location.href = this.achieveConfigService.settings.loginUrl;
    } else {
      this.router.navigate(['auth', 'login']);
    }
  }

  logout(redirectUrl?: string) {
    this.redirectUrl = '';
    this.http.post('/api/logout', {}).subscribe(() => {
      this.user = null;
      window.location.href = redirectUrl
        ? redirectUrl
        : this.achieveConfigService.settings.loginUrl
        ? this.achieveConfigService.settings.loginUrl
        : '/auth/login';
    });
  }

  authenticate(redirectUrl = '') {
    this.user = null;
    this.redirectUrl = redirectUrl;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.get<AuthUser>('/api/auth').subscribe(this.processAuthUser(sub), (err: any) => sub.error(err));
    });
  }

  nonInterceptedAuthenticate(redirectUrl = '') {
    this.user = null;
    this.redirectUrl = redirectUrl;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.nonInterceptedHttpClient.get<AuthUser>('/api/auth').subscribe(this.processAuthUser(sub), (err: any) => sub.error(err));
    });
  }

  navigatePostAuth() {
    this.router.navigate([this.redirectUrl]);
    this.redirectUrl = '';
  }

  navigateToPortal() {
    this.router.navigate(['/portal']);
    this.redirectUrl = '';
  }

  navigateToVr() {
    this.router.navigate(['/ivrs']);
    this.redirectUrl = '';
  }

  navigateToInteractiveAccess() {
    this.router.navigate(['/interactive-access']);
    this.redirectUrl = '';
  }

  navigateToStateComplaintDashboard() {
    this.router.navigate(['/', 'my-tools', 'dispute-resolution', 'state-complaint']);
    this.redirectUrl = '';
  }

  navigateToStateComplaintCaseDetails(caseId: string) {
    this.router.navigate(['/', 'my-tools', 'dispute-resolution', 'state-complaint', caseId, 'dashboard']);
    this.redirectUrl = '';
  }

  private processAuthUser(sub: Subscriber<any>) {
    return (user: AuthUser) => {
      this.user = user;

      //if (this.isPortalUser) {
      //  this.navigateToPortal();
      //}

      sub.next(user);
      sub.complete();
    };
  }

  getRoles() {
    return this.http.get<Role[]>('api/roles');
  }

  getUserRoleAndLocations(userId: string) {
    return this.http.get<UserRole[]>(`api/get-user-role-locations/${userId}`);
  }

  getFamilyInvite(inviteId: string) {
    return this.http.get<EarlyAccessQuestionnaireInvite>(`api/early-access-questionnaires/invites/${inviteId}`);
  }

  registerFamilyMember(registration: FamilyMemberRegistration) {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('api/register-family-member', registration).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Registration failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  addFamilyMember(registration: FamilyMemberAdd) {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('api/add-family-member', registration).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Add failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  registerLearner(registration: LearnerRegistration) {
    this.user = null;
    return new Observable<AuthUser>((sub: Subscriber<any>) => {
      this.http.post<AuthUser>('api/register-learner', registration).subscribe(this.processAuthUser(sub), (err: any) => {
        console.error('Registration failed');
        this.user = null;
        sub.error(err);
      });
    });
  }

  forgotPassword(model: ForgotPasswordViewModel) {
    return this.http.post<OperationResult>('api/forgot-password', model);
  }

  ivrsForgotPassword(model: ForgotPasswordViewModel) {
    return this.http.post<OperationResult>('api/ivrs-forgot-password', model);
  }

  interactiveAccessForgotPassword(model: ForgotPasswordViewModel) {
    return this.http.post<OperationResult>('api/interactive-access-forgot-password', model);
  }

  resetPassword(model: ResetPasswordViewModel) {
    return this.http.post<OperationResult>('api/reset-password', model);
  }

  ivrsResetPassword(model: ResetPasswordViewModel) {
    return this.http.post<OperationResult>('api/ivrs-reset-password', model);
  }

  interactiveAccessResetPassword(model: ResetPasswordViewModel) {
    return this.http.post<OperationResult>('api/interactive-access-reset-password', model);
  }

  deleteUserRole(userRoleId: string, userId: string) {
    const params = new HttpParams().append('userId', userId);
    return this.http.put<User>(`api/user-role/delete/${userRoleId}`, null, { params });
  }

  setUserRoleForPermissions(userRoles: UserRoleUpdate) {
    return this.http.post<User>('api/set-user-roles', userRoles);
  }

  canShareCase(caseId: string, intakeType: IntakeType) {
    if (!caseId || !intakeType) {
      return false;
    }

    let roles = [];
    if (intakeType === IntakeType.PartB) {
      roles = ['AeaEdit', 'LeaProviderEdit'];
    } else {
      roles = ['ServiceCoordinator', 'AeaEdit', 'LeaProviderEdit'];
    }

    return (
      this.isAllowedByCaseId(caseId, undefined, AppPermissions.ShareAllCases) ||
      this.isAllowedByCaseId(caseId, new Map<string, string[]>([['TeamMember', roles]]), AppPermissions.ShareOwnCases) ||
      this.isAllowedWithAdditionalRequirements(
        new Map<string, string[]>([['TeamMember', ['StateWideEdit']]]),
        caseId,
        AppPermissions.ShareIfPartOfCase
      )
    );
  }

  canTransferCase(caseId: string, intakeType: IntakeType) {
    if (!caseId || !intakeType) {
      return false;
    }

    let roles = [];
    if (intakeType === IntakeType.PartB) {
      roles = ['AeaEdit', 'LeaProviderEdit', 'ACHIEVEDataLead'];
    } else {
      roles = ['ServiceCoordinator', 'AeaEdit', 'LeaProviderEdit', 'ACHIEVEDataLead'];
    }

    return (
      this.isAllowedByCaseId(caseId, undefined, AppPermissions.TransferAllCases) ||
      this.isAllowedByCaseId(caseId, undefined, AppPermissions.TransferIfPartOfCase) ||
      this.isAllowedByCaseId(caseId, new Map<string, string[]>([['TeamMember', roles]]), AppPermissions.TransferOwnCases) ||
      this.isAllowedByCaseId(caseId, new Map<string, string[]>([['TeamMember', roles]]), AppPermissions.TransferIfPartOfCase) ||
      this.isAllowedWithAdditionalRequirements(
        new Map<string, string[]>([['TeamMember', ['StateWideEdit']]]),
        caseId,
        AppPermissions.TransferIfPartOfCase
      )
    );
  }

  canMessageFamily(caseId: string) {
    if (!caseId) {
      return false;
    }
    let roles = [];
    roles = ['StateWideEdit', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator'];

    return this.isAllowedWithAdditionalRequirements(
      new Map<string, string[]>([['CaseOwner', roles]]),
      caseId,
      AppPermissions.MessageFamily
    );
  }

  hasClaim(claimType: ClaimTypes) {
    return !!this.user?.claims.find((c) => c.value === claimType);
  }

  async getToken(): Promise<string> {
    return await this.http.get('/api/auth/token', { responseType: 'text' }).toPromise();
  }

  get isComplainant() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.Complainant);
  }

  get isRespondent() {
    return !!this.user?.rolePermissions.find((c) => c.role === Roles.Respondent);
  }
}

// TODO: for development/testing only. Remove once we have realy SAML integration from IDOE.
const SAML = `<trust:RequestSecurityTokenResponseCollection xmlns:trust="http://docs.oasis-open.org/ws-sx/ws-trust/200512">
    <trust:RequestSecurityTokenResponse Context="rm=0&amp;id=passive&amp;ru=%2fACHIEVE_SAMLBridge%2f">
        <trust:Lifetime>
            <wsu:Created xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">2019-05-29T16:31:46.949Z</wsu:Created>
            <wsu:Expires xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">2019-05-29T17:31:46.949Z</wsu:Expires>
        </trust:Lifetime>
        <wsp:AppliesTo xmlns:wsp="http://schemas.xmlsoap.org/ws/2004/09/policy">
            <EndpointReference xmlns="http://www.w3.org/2005/08/addressing">
                <Address>http://idoe-dev.azurewebsites.net/</Address>
            </EndpointReference>
        </wsp:AppliesTo>
        <trust:RequestedSecurityToken>
            <saml:Assertion MajorVersion="1"
                            MinorVersion="1"
                            AssertionID="_97b7d27c-78a1-45a4-b862-f9523c58f6a1"
                            Issuer="PassiveSigninSTS"
                            IssueInstant="2019-05-29T16:31:46.949Z"
                            xmlns:saml="urn:oasis:names:tc:SAML:1.0:assertion"
                            >
                <saml:Conditions NotBefore="2019-05-29T16:31:46.949Z"
                                 NotOnOrAfter="2019-05-29T17:31:46.949Z"
                                 >
                    <saml:AudienceRestrictionCondition>
                        <saml:Audience>http://idoe-dev.azurewebsites.net/</saml:Audience>
                    </saml:AudienceRestrictionCondition>
                </saml:Conditions>
                <saml:AttributeStatement>
                    <saml:Subject>
                        <saml:SubjectConfirmation>
                            <saml:ConfirmationMethod>urn:oasis:names:tc:SAML:1.0:cm:bearer</saml:ConfirmationMethod>
                        </saml:SubjectConfirmation>
                    </saml:Subject>
                    <saml:Attribute AttributeName="userid"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>***userid***</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="role"
                                    AttributeNamespace="http://schemas.microsoft.com/ws/2008/06/identity/claims"
                                    >
                        <saml:AttributeValue>ACHIEVE_USER</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="applicationname"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>ACHIEVE</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="organizationname"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>AHSTW Comm School District</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="orgcode"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>04410000</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="combinations"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>ACHIEVE</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="firstname"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>Test</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="lastname"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>Account</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="emailaddress"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>test-user@iowaachieve.com</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="userroles"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>01hbjwvUm9sZU5hbWU+PEFjY2Vzc0xldmVsPkRJU1Q8L0FjY2Vzc0xldmVsPjxBY2Nlc3NJZD4wNDQxMDAwMDwvQWNjZXNzSWQ+PC9Sb2xlPjxSb2xlPjxSb2xlTmFtZT5DQVNBX0NvbkFwcDwvUm9sZU5hbWU+PEFjY2Vzc0xldmVsPkRJU1Q8L0FjY2Vzc0xldmVsPjxBY2Nlc3NJZD4wNDQxMDAwMDwvQWNjZXNzSWQ+PC9Sb2xlPjwvUm9sZXM+</saml:AttributeValue>
                    </saml:Attribute>
                    <saml:Attribute AttributeName="AccessLevel"
                                    AttributeNamespace="http://schemas.xmlsoap.org/ws/2005/05/identity/claims"
                                    >
                        <saml:AttributeValue>DIST</saml:AttributeValue>
                    </saml:Attribute>
                </saml:AttributeStatement>
            </saml:Assertion>
        </trust:RequestedSecurityToken>
    </trust:RequestSecurityTokenResponse>
</trust:RequestSecurityTokenResponseCollection>`;
