import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Aea } from '../../models/aea';
import { AppPermissions } from 'src/app/permissions';
import { OperationResultWithValue } from '../../models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class AeaService {
  private baseUrl = 'api/area-education-agencies/';

  constructor(private http: HttpClient) {}

  getAllAeas() {
    return this.http.get<Aea[]>(this.baseUrl);
  }

  getPublicAeas() {
    return this.http.get<Aea[]>(this.baseUrl + 'public');
  }

  getAeaInformation(id: string): Observable<Aea> {
    return this.http.get<Aea>(`${this.baseUrl}${id}`);
  }

  getByPermission(
    includeDistrict: boolean,
    includeBuilding: boolean,
    isStateWide = false,
    ...perms: AppPermissions[]
  ): Observable<OperationResultWithValue<any>> {
    let params = new HttpParams();
    params = params.append('isStateWide', isStateWide.toString());
    for (const perm of perms) {
      params = params.append('permissions', perm);
    }
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}by-permission/${includeDistrict}/${includeBuilding}`, { params });
  }
}
