import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DhhLearnerDto } from '../../../models/DhhDtos';
import { DhhService } from '../../../services/dhh.service';

@Component({
  selector: 'app-dhh-pending-learner-update',
  templateUrl: './dhh-pending-learner-update.component.html',
  styleUrls: ['./dhh-pending-learner-update.component.scss'],
})
export class DhhPendingLearnerUpdateComponent implements OnInit {
  learnerId: string;
  learner: DhhLearnerDto;
  pageTitle = 'Pending Learner';

  constructor(private route: ActivatedRoute, private readonly dhhService: DhhService, private readonly router: Router) {}

  ngOnInit(): void {
    this.learnerId = this.route.snapshot.paramMap.get('learnerId');

    this.dhhService.getLearner(this.learnerId).subscribe(
      (result) => {
        if (result.succeeded) {
          this.learner = result.value;
          if (this.learner.stateAssignedId) {
            //Pending learners won't have state assigned id, prevent further processing on this page
            this.router.navigate(['unauthorized']);
          }
        } else {
          this.dhhService.handleError('Failed to load learner data', result);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error while retrieving learner data', error);
      }
    );
  }

  onComplete() {
    this.router.navigate(['dhh', 'pending-learners']);
  }

  onClose() {
    //during update, onComplete and onClose have the same effect
    this.router.navigate(['dhh', 'pending-learners']);
  }
}
