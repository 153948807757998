import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { ForgotPasswordViewModel } from '../../../auth/auth-models';
import { AppEvents, AutosavingStates } from '../../../shared/app-events';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import { usStateOptions, yesNoOptions } from '../../../shared/formHelpers';
import { CaseSummary, IntakeType } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { FamilyMember, FamilyMemberType, LearnerSummary } from '../../../shared/models/learner';
import { AchieveConfigService } from '../../../shared/services/achieve-config-service/achieve-config.service';
import { CaseService } from '../../../shared/services/case/case.service';
import { DeactivationService } from '../../../shared/services/deactivation.service';
import { FamilyMemberService } from '../../../shared/services/family-member/family-member.service';
import { FamilyRelationshipService } from '../../../shared/services/family-relationship/family-relationship.service';
import { NotificationService } from '../../../shared/services/notification.service';
import * as AppValidators from '../../../shared/validators';
import { openPdfWindow } from '../../../shared/windowHelpers';
import { FamilyContactInfoViewMoreComponent } from '../family-contact-info-view-more/family-contact-info-view-more.component';
import { getFamilyMemberTypeFromLabel } from '../../../shared/family-member-type-helpers';
import { FamilyRelationship } from '../../../shared/models/family-relationship';
import dayjs from 'dayjs';

@Component({
  selector: 'app-family-contact-info',
  templateUrl: './family-contact-info.component.html',
  styleUrls: ['./family-contact-info.component.scss'],
})
export class FamilyContactInfoComponent extends BaseComponent implements OnInit, AfterViewChecked {
  @Input() learner: LearnerSummary;
  @ViewChild('surrogateSection') surrogateSection: ElementRef;

  permissions = AppPermissions;

  dataSource = new MatTableDataSource<FamilyMember>();

  displayedColumns = ['actions', 'fullName', 'email', 'homePhone', 'familyRelationship', 'validated', 'status', 'familyAccess'];

  isEditAllowed = true;
  isReadOnly = false;
  editTitle = 'Edit';
  relationships: FamilyRelationship[] = [];
  relationshipOptions: KeyValuePair[] = [];
  isEditing = false;
  yesNoOptions = yesNoOptions;
  usStateOptions = usStateOptions;
  activeCall = false;
  viewEmailtheFamilyAdditionalRequirements = new Map<string, string[]>([
    ['CaseOwner', ['StateWideEdit', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
  ]);
  viewSchedulerAdditionalRequirements = new Map<string, string[]>([
    ['TeamMember', ['ACHIEVEDataLead', 'ACHIEVEDataTechnician', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
  ]);
  printContactLetterAdditionalRequirements = new Map<string, string[]>([['CaseOwner', ['LeaProviderEdit', 'ServiceCoordinator']]]);

  formGroup = this.fb.group(
    {
      id: [null],
      fullName: [null, [Validators.required, AppValidators.noNumbersValidator]],
      type: [null, Validators.required],
      email: [null, Validators.email],
      livesWithChild: [null, Validators.required],
      streetAddress: [null],
      city: [null],
      zipCode: [null],
      state: [null],
      homePhone: [null, AppValidators.phoneValidator],
      workPhone: [null, AppValidators.phoneValidator],
      cellPhone: [null, AppValidators.phoneValidator],
      bestWayToReachHomePhone: [null],
      bestWayToReachCellPhone: [null],
      bestWayToReachWorkPhone: [null],
      bestWayToReachEmail: [null],
      bestWayToReachText: [null],
      bestTimeToContact: [null],
      isPersonCompletingReferral: [null],
      familyRelationshipId: [null, Validators.required],
      familyRelationship: [null],
      familyUserId: [null],
      noPortalAccess: [null],
      noSigningRights: [null],
      validated: [null],
      accessRevoked: [null],
    },
    {
      validators: [bestWayToReachValidator('parent1')],
    }
  );

  // this is just here to make the family member component happy
  parent = this.fb.group({
    parent1: this.formGroup,
  });

  showSurrogateButton = true;
  showCancelSurrogateButton = false;
  showSurrogateSection = false;
  isFirstSurrogate = false;

  // used to validate if the user is not actually changing the type
  originalParentType = FamilyMemberType.Parent1;

  public partCActiveCase: CaseSummary;

  get canEmailTheFamily() {
    return this.authService.isAllowedByCaseId(
      this.learnerService.learnerSummary.activeCases[0]?.id,
      new Map<string, string[]>([['CaseOwner', ['StateWideEdit', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']]]),
      AppPermissions.MessageFamily
    );
  }

  get canAddSurrogates() {
    return this.authService.canEditSurrogates;
  }

  get IsAbleForScheduleMeeting() {
    return (
      this.authService.isAllowedByCaseId(
        this.learnerService.learnerSummary.activeCases[0]?.id,
        undefined,
        AppPermissions.ScheduleMeeting
      ) ||
      this.authService.isAllowedByCaseId(
        this.learnerService.learnerSummary.activeCases[0]?.id,
        new Map<string, string[]>([
          ['TeamMember', ['ACHIEVEDataLead', 'ACHIEVEDataTechnician', 'AeaEdit', 'LeaProviderEdit', 'ServiceCoordinator']],
        ]),
        AppPermissions.ScheduleMeeting
      )
    );
  }

  get IsAbleForPrintLetter() {
    return this.partCActiveCase && (this.authService.isCaseOwner(this.partCActiveCase.id) || this.authService.isSuperAdmin);
  }

  get canViewActions() {
    return this.authService.isAllowedByCaseId(
      this.learnerService.learnerSummary.activeCases[0]?.id,
      undefined,
      AppPermissions.ViewActionsFamilyContact
    );
  }

  get canInviteFamilyMember() {
    const hasPermission =
      this.authService.isLeaUserManager ||
      this.authService.isDataLead ||
      this.authService.isDataTechnician ||
      this.authService.isSuperAdmin;

    return this.learner.isEligibleForFamilyPortal && this.isFamilyPortalEnabled && (this.isCaseOwner || hasPermission);
  }

  get isCaseOwner() {
    return this.learner.activeCases.some((c) => this.authService.isCaseOwner(c.id));
  }

  get canManageFamilyMemberAccess() {
    return this.learner.isEligibleForFamilyPortal && (this.authService.isSuperAdmin || this.authService.isDataLead);
  }

  get isFamilyPortalEnabled() {
    return this.achieveConfigService.settings?.featureFlags?.familyPortalEnabled;
  }

  constructor(
    public authService: AuthService,
    public learnerService: LearnerService,
    private routingService: RoutingService,
    private readonly familyMemberRelationshipService: FamilyRelationshipService,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly reportingService: ReportingService,
    private readonly caseService: CaseService,
    private readonly changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    deactivationService: DeactivationService,
    private familyMemberService: FamilyMemberService,
    private achieveConfigService: AchieveConfigService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.showSurrogateButton = this.learner.surrogates.length === 0;
    this.showSurrogateSection = this.learner.surrogates.length > 0;
    const partCCase = this.learner.activeCases.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate);
    if (partCCase) {
      this.partCActiveCase = await this.caseService.getCaseSummary(partCCase.id).toPromise();
    }

    if (!this.isEditAllowed || !this.learner?.hasWorkableCase) {
      this.displayedColumns = this.displayedColumns.splice(1);
    }
    this.familyMemberRelationshipService.get().subscribe((res) => (this.relationships = res));
    this.dataSource.data = this.learner.familyMembers;

    this.formGroup.controls.familyRelationshipId.valueChanges.subscribe((value) => this.familyRelationshipOnChange(value));

    if (!this.isFamilyPortalEnabled) {
      const indexOfPortalStatus = this.displayedColumns.indexOf('status', 3);
      if (indexOfPortalStatus > -1) {
        this.displayedColumns.splice(indexOfPortalStatus, 1);
      }
    }
  }

  relationshipAllowed(relationshipId: string) {
    const relationship = this.relationships.find((x) => x.id === relationshipId);
    const isLegalGuardianOrPowerOfAttorney = ['Legal Guardian of Protected Person', 'Power of Attorney'].includes(relationship?.label);
    const isLearner = relationship?.label === 'Learner';
    const hasRightsTransferDate = this.learner.iepInformation?.rightsWillNotTransfer && this.learner.iepInformation?.dateRightsWillTransfer;
    const isAdult = dayjs(this.learner.dateOfBirth).isBefore(dayjs().subtract(18, 'years'));

    if (isLegalGuardianOrPowerOfAttorney || isLearner) {
      if (hasRightsTransferDate) {
        return dayjs(this.learner.iepInformation?.dateRightsWillTransfer).isBefore(dayjs());
      }
      return isAdult;
    }
    return this.relationships.some((x) => x.id === relationshipId && x.allowedPortalAccess);
  }

  familyRelationshipOnChange(familyRelationshipId: string) {
    if (!familyRelationshipId) return;

    const optionName = KeyValuePair.getValue(this.relationshipOptions, familyRelationshipId);

    if (optionName === 'Parent (Biological or Adoptive)') {
      const existingParents = this.dataSource.data?.filter(
        (f) => f.type === FamilyMemberType.Parent1 || f.type === FamilyMemberType.Parent2 || f.type === FamilyMemberType.Parent
      );
      const currentRecordExits = this.dataSource.data?.find((f) => f.id === this.formGroup.controls.id.value);

      // if current record is already parent, no need further action
      if (currentRecordExits && existingParents.some((f) => f.id === currentRecordExits.id)) return;

      // update type accordingly
      if (existingParents && existingParents.some((f) => f.type === FamilyMemberType.Parent1)) {
        if (existingParents.some((f) => f.type === FamilyMemberType.Parent2)) {
          this.formGroup.controls.type.setValue(FamilyMemberType.Parent);
        } else {
          this.formGroup.controls.type.setValue(FamilyMemberType.Parent2);
        }
      } else {
        this.formGroup.controls.type.setValue(FamilyMemberType.Parent1);
      }
    } else {
      this.formGroup.controls.type.setValue(optionName);
      this.formGroup.controls.familyRelationship.setValue(optionName);
    }

    return;
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  moreThanOneParent(item) {
    if (item.type !== 'Parent1' && item.type !== 'Parent2' && item.type !== 'Parent') {
      return true;
    }

    return (
      this.dataSource.data.filter(
        (x) => x.type === FamilyMemberType.Parent1 || x.type === FamilyMemberType.Parent2 || x.type === FamilyMemberType.Parent
      )?.length > 1
    );
  }

  onAddFirstSurrogates() {
    this.showSurrogateButton = false;
    this.showSurrogateSection = true;
    this.isFirstSurrogate = true;
    setTimeout(
      () =>
        this.surrogateSection.nativeElement.scrollIntoView({
          behavior: 'smooth',
        }),
      250
    );
  }

  onCancelSurrogateEntry() {
    this.showSurrogateButton = true;
    this.showSurrogateSection = false;
  }

  scheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.learner.id);
  }

  onPrintContactLetter() {
    const pdfId = this.partCActiveCase.caseClosureLetterDocumentId;
    if (pdfId) {
      openPdfWindow(this.learner.id, pdfId);
    } else {
      this.reportingService.createCaseClosureLetter(this.partCActiveCase.id).subscribe({
        next: (documentId: string) => this.handleOutputCreation(documentId),
        error: (err) =>
          this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
            this.notificationService.alert(err.error, "Couldn't open output")
          ),
      });
    }
  }

  private handleOutputCreation(documentId: string) {
    this.partCActiveCase.caseClosureLetterDocumentId = documentId;
    openPdfWindow(this.learner.id, documentId);
  }

  edit(family: FamilyMember) {
    const formFields = [
      'familyRelationshipId',
      'fullName',
      'livesWithChild',
      'streetAddress',
      'city',
      'state',
      'zipCode',
      'homePhone',
      'workPhone',
      'cellPhone',
    ];
    if (family.type === FamilyMemberType.Learner) {
      formFields.forEach((field) => this.formGroup.controls[field].disable());
    } else {
      formFields.forEach((field) => this.formGroup.controls[field].enable());
    }
    this.formGroup.controls.familyRelationshipId.updateValueAndValidity();

    combineLatest([this.getFamilyRelationships(), this.learnerService.getFamilyMember(this.learner.id, family.id)])
      .pipe(take(1))
      .subscribe(([_, familyMember]) => {
        this.learner.familyMembers = this.learner.familyMembers.map((fm) => (fm.id === family.id ? familyMember : fm));
        this.dataSource.data = this.learner.familyMembers;
        this.formGroup.patchValue(familyMember);
        this.originalParentType = familyMember.type;
        this.isEditing = true;
      });
  }

  delete(family: FamilyMember) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion:
          'Clicking Yes will remove this family member. If this family member has family portal access, their access will be revoked.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.learnerService.deleteFamilyMember(this.learner.id, family.id).subscribe(() => {
          this.dataSource.data = this.dataSource.data.filter((x) => x.id !== family.id);
          this.learner.familyMembers = this.learner.familyMembers.filter((x) => x.id !== family.id);
        });
      }
    });
  }

  addingNew() {
    this.getFamilyRelationships()
      .pipe(take(1))
      .subscribe(() => {
        this.formGroup.get('familyRelationshipId').enable();
        this.isEditing = true;
      });
  }

  cancelEdit() {
    this.parent.reset();
    this.isEditing = false;
  }

  saveEdit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.activeCall = true;

    const formFields = [
      'familyRelationshipId',
      'fullName',
      'livesWithChild',
      'streetAddress',
      'city',
      'state',
      'zipCode',
      'homePhone',
      'workPhone',
      'cellPhone',
    ];
    formFields.forEach((field) => this.formGroup.controls[field].enable());
    this.formGroup.controls.familyRelationshipId.updateValueAndValidity();
    const familyRelationshipValue = KeyValuePair.getValue(this.relationshipOptions, this.formGroup.controls.familyRelationshipId.value);
    const familyMemberToTable = () => {
      const member = {
        id: this.formGroup.controls.id.value,
        fullName: this.formGroup.controls.fullName.value,
        email: this.formGroup.controls.email.value,
        homePhone: this.formGroup.controls.homePhone.value,
        workPhone: this.formGroup.controls.workPhone.value,
        cellPhone: this.formGroup.controls.cellPhone.value,
        familyUserId: this.formGroup.controls.familyUserId.value,
        type: this.formGroup.controls.type.value,
        familyRelationshipId: this.formGroup.controls.familyRelationshipId.value,
        familyRelationship: familyRelationshipValue,
        noPortalAccess: this.formGroup.controls['noPortalAccess'].value,
        noSigningRights: this.formGroup.controls['noSigningRights'].value,
        validated: this.formGroup.controls['validated'].value,
        accessRevoked: this.formGroup.controls['accessRevoked'].value,
      } as FamilyMember;

      return member;
    };

    AppEvents.autosaving.emit(AutosavingStates.Saving);
    const saveDone = () => {
      this.cancelEdit();
      AppEvents.autosaving.emit(AutosavingStates.OneOff);
      this.activeCall = false;
    };

    const saveFailed = () => {
      AppEvents.autosaving.emit(AutosavingStates.Failed);
      this.activeCall = false;
    };

    if (this.formGroup.pristine) {
      saveDone();
      return;
    }

    const model = {
      ...this.formGroup.value,
    };
    model.familyMember = familyRelationshipValue?.replace(/\s+/g, '');
    model.type = getFamilyMemberTypeFromLabel(model.type);

    const updateFamilyMember = () => {
      this.subscriptions.add(
        this.learnerService.updateFamilyMember(this.learner.id, model).subscribe(
          () => {
            const data = this.dataSource.data.filter((x) => x.id !== this.formGroup.controls.id.value);
            data.push(familyMemberToTable());
            this.dataSource.data = data;
            this.learner.familyMembers = data;
            saveDone();
          },
          () => saveFailed()
        )
      );
    };

    const addFamilyMember = () => {
      this.subscriptions.add(
        this.learnerService.addFamilyMember(this.learner.id, model).subscribe(
          (result) => {
            this.formGroup.controls.id.setValue(result.id);
            const data = this.dataSource.data;
            data.push(familyMemberToTable());
            this.dataSource.data = data;
            this.learner.familyMembers = data;
            saveDone();
          },
          () => saveFailed()
        )
      );
    };

    if (model.validated && !model.email) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          question: 'Are you sure?',
          subQuestion: 'Are you sure you wish to validate this information without including an email address?',
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          if (this.formGroup.controls.id.value) {
            updateFamilyMember();
          } else {
            addFamilyMember();
          }
        } else {
          AppEvents.autosaving.emit(AutosavingStates.Off);
          this.activeCall = false;
        }
      });
    } else {
      if (this.formGroup.controls.id.value) {
        updateFamilyMember();
      } else {
        addFamilyMember();
      }
    }
  }

  private getFamilyRelationships() {
    return new Observable((observer) => {
      if (this.relationshipOptions.length !== 0) {
        observer.next(true);
        return;
      }

      this.subscriptions.add(
        this.familyMemberRelationshipService.get().subscribe((relationships) => {
          this.relationshipOptions = relationships.map((r) => new KeyValuePair(r.id, r.label, false, r.isActive));
          observer.next(true);
          return;
        })
      );
    });
  }

  private isParent(): boolean {
    return (
      this.formGroup.controls.type.value === FamilyMemberType.Parent1 ||
      this.formGroup.controls.type.value === FamilyMemberType.Parent2 ||
      this.formGroup.controls.type.value === FamilyMemberType.Parent
    );
  }

  public async viewMoreFamilyMemberModal(familyMemberId: string) {
    const familyMember = await this.learnerService.getFamilyMember(this.learner.id, familyMemberId).toPromise();
    this.dialog.open(FamilyContactInfoViewMoreComponent, {
      width: '728px',
      data: familyMember,
    });
  }

  onInviteFamilyMember(familyMemberId: string) {
    this.activeCall = true;
    this.familyMemberService.inviteFamilymember(familyMemberId).subscribe(
      (response) => {
        this.activeCall = false;
        if (response.succeeded) {
          this.notificationService.success('Invitation sent!');
        } else {
          this.notificationService.error('Invitation failed');
        }
      },
      () => {
        this.activeCall = false;
        this.notificationService.error('Invitation failed');
      }
    );
  }

  resetPassword(familyMember: FamilyMember) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to send reset password request/email?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const model = { email: familyMember.email } as ForgotPasswordViewModel;
        this.authService.forgotPassword(model).subscribe(
          (response) => {
            this.notificationService.success('Reset password email has been sent.');
          },
          (error) => {
            this.notificationService.error(error?.error);
          }
        );
      }
    });
  }

  getPortalStatus(familyMember: FamilyMember) {
    return familyMember.familyUserId !== null
      ? familyMember.accessRevoked
        ? 'Revoked'
        : familyMember.isFamilyMemberReadOnly
        ? 'Active - View Only'
        : 'Active'
      : '';
  }

  revokeAccess(familyMember: FamilyMember) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to revoke access from this account?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.familyMemberService.revokeAccess(familyMember.id).subscribe(
          (accountStatus) => {
            familyMember.accessRevoked = true;
            familyMember.isUserAccountActive = accountStatus.value;
            this.notificationService.success('Successfully revoked user access');
          },
          () => {
            this.notificationService.error('Revoking access failed');
          }
        );
      }
    });
  }

  reactivateAccess(familyMember: FamilyMember) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to grant access to this account?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.familyMemberService.reactivateAccess(familyMember.id).subscribe(
          () => {
            familyMember.accessRevoked = false;
            familyMember.isUserAccountActive = true;
            this.notificationService.success('Successfully granted user access');
          },
          () => {
            this.notificationService.error('Granting access failed');
          }
        );
      }
    });
  }
}

const bestWayToReachValidator =
  (parent: string): ValidatorFn =>
  (control: FormGroup): ValidationErrors | null => {
    const homePhone = control.get('homePhone');
    const homePhoneIsBest = control.get('bestWayToReachHomePhone');
    const cellPhone = control.get('cellPhone');
    const cellPhoneIsBest = control.get('bestWayToReachCellPhone');
    const textIsBest = control.get('bestWayToReachText');
    const workPhone = control.get('workPhone');
    const workPhoneIsBest = control.get('bestWayToReachWorkPhone');
    const email = control.get('email');
    const emailIsBest = control.get('bestWayToReachEmail');

    if (
      (!!homePhoneIsBest.value && !homePhone.value) ||
      (!!workPhoneIsBest.value && !workPhone.value) ||
      (!!cellPhoneIsBest.value && !cellPhone.value) ||
      (!!textIsBest.value && !cellPhone.value) ||
      (!!emailIsBest.value && !email.value)
    ) {
      const errors = {};
      errors[`${parent}OnePhoneNumberContactRequired`] = true;
      return errors;
    } else {
      return null;
    }
  };
